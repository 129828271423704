<template>

<div class="submenu" v-if="pages.length && isExpanded" :class="{'is-many': pages.length > 10}">

	<router-link :to="$link(page.url)" v-for="(page, index) in pages" :key="index" class="submenu-item">{{ page.name }}</router-link>

</div>

</template>

<script>

export default {

	props: ['pages', 'isExpanded']

}

</script>

<style scoped>

.submenu {
	background-color: #1A234C;
	left: 0px;
	z-index: 10;
	width: 240px;
	position: absolute;
	display: grid;
	grid-template-columns: 1fr;
	padding: 5px 0px;
}

.submenu.is-many {
	grid-template-columns: 1fr 1fr;
	width: 480px;
}

.is-device .submenu  {
	position: relative;
	width: 100%;
}

.submenu-item {
	color: #fff;
	font-size: 16px;
	line-height: 20px;
	padding: 5px 10px;
	display: block;
}

.is-tablet .submenu-item {
	padding: 5px;
}

.is-tablet .submenu-item:before {
	content: '- ';
}

.submenu-item:hover {
	text-decoration: underline;
}

</style>
